import { AxiosResponse } from 'axios';
import { getAssetsGrouped, getAssetsHoldback, getAssetStatusCount } from 'base/api/dashboard';
import { Assets } from 'base/models/Asset';
import { GroupType, statusCountDefault, StatusCountType } from 'base/models/DashboardProps';
import { RootStore } from 'base/stores/rootStore';
import { ResolvePayload } from 'base/utils/resolveAPICall';
import { action, computed, makeObservable, observable } from 'mobx';

interface GroupedAsset {
  id: string;
  mam: string;
  createdAt: Date;
  updatedAt: Date;
  jobId: string;
  assetType: string;
  title: string;
  assets: Assets;
  user: string;
  totalAssets: number;
}

type GroupedAssets = GroupedAsset[];

interface ResponseCount {
  status: string;
  count: number;
}

export type ResponseCounts = ResponseCount[];

export class AssetStore {
  rootStore: RootStore;

  constructor(rootstore: RootStore) {
    makeObservable(this);
    this.rootStore = rootstore;
  }

  @observable statusTotal: StatusCountType = statusCountDefault;
  @observable gridAssets: GroupedAssets = [];
  @observable isError = false;
  @observable assets: Assets[] = [];
  @observable isBackdropOpen = false;
  @observable isLoadingGrid = false;
  @observable isLoadingMenu = false;

  @observable portalId = '00c1b88f-5cf3-471b-903e-ebc0efc67234';

  @action setIsBackdrop = (value: boolean) => {
    this.isBackdropOpen = value;
  };

  @action setStatusTotal = (statusTotal: StatusCountType) => {
    if (statusTotal) this.statusTotal = { ...statusTotal };
  };

  @action processAssets = (assets: Assets[]) => {
    const newGroupedAssets = assets.reduce((currentValue, group) => {
      const [firstGroup] = group;
      return [
        ...currentValue,
        {
          id: firstGroup.id,
          mam: firstGroup.mam,
          createdAt: firstGroup?.created?.date,
          updatedAt: firstGroup?.modified?.date,
          jobId: firstGroup?.jobId,
          assetType: firstGroup?.assetGroup?.name ? firstGroup.assetGroup.name : 'Unassigned',
          title: firstGroup?.title?.name ? firstGroup.title.name : 'Unassigned',
          user: firstGroup?.created?.user.name,
          assets: group,
          totalAssets: group.length,
          cpmProductId: firstGroup?.title?.cpmProductId,
          releaseDate: firstGroup?.title?.releaseDate ?? '',
          wprId: firstGroup?.title?.wprId ?? null,
        } as GroupedAsset,
      ];
    }, [] as GroupedAssets);
    this.gridAssets = newGroupedAssets;
  };

  @action fetchGetAssetsHoldback = (email: string, columnType: GroupType) => {
    this.isLoadingGrid = true;
    getAssetsHoldback(email, columnType).then(
      this.getAssetsFetchSuccess,
      this.getAssetsFetchFailure
    );
  };

  @action fetchGetAssetsGrouped = (
    filter: number,
    columnType: GroupType,
    email: string,
    updateCounter?: boolean
  ) => {
    this.isLoadingGrid = true;
    getAssetsGrouped(filter, columnType, email)
      .then(this.getAssetsFetchSuccess, this.getAssetsFetchFailure)
      .then(() => updateCounter && this.fetchGetAssetsStatusCount());
  };

  @action getAssetsFetchSuccess = ({
    response,
    error,
  }: ResolvePayload<AxiosResponse<Assets[]>>) => {
    if (response && response) {
      this.isError = false;
      this.isLoadingGrid = false;

      this.assets = response.data;

      this.processAssets(this.assets);
    }
    if (error) {
      this.isError = true;
    }
  };

  @action getAssetsFetchFailure = (_error: Error | null) => {
    this.isError = true;
  };

  @action fetchGetAssetsStatusCount = () => {
    this.isLoadingGrid = true;
    const userName = this.rootStore.dashboardStore.userFilter;
    if (userName && userName.email) {
      getAssetStatusCount(userName.email).then(
        this.getAssetsStatusCountFetchSuccess,
        this._getAssetsStatusCountFetchFailure
      );
    }
  };

  @action getAssetsStatusCountFetchSuccess = ({
    response,
    error,
  }: ResolvePayload<AxiosResponse<ResponseCounts>>) => {
    let counterResult = statusCountDefault;
    if (response && response.data) {
      const responseCounts = response.data;
      let tempStatusCount: StatusCountType = statusCountDefault;

      for (const [, value] of Object.entries(responseCounts)) {
        switch (value.status) {
          case 'Draft':
            tempStatusCount = { ...tempStatusCount, Draft: value.count };
            break;
          case 'InProgress':
            tempStatusCount = { ...tempStatusCount, InProgress: value.count };
            break;
          case 'NotPublished':
            tempStatusCount = { ...tempStatusCount, NotPublished: value.count };
            break;
          case 'Published':
            tempStatusCount = { ...tempStatusCount, Published: value.count };
            break;
          case 'Failed':
            tempStatusCount = { ...tempStatusCount, Failed: value.count };
            break;
          case 'Holdback':
            tempStatusCount = { ...tempStatusCount, Holdback: value.count };
            break;
        }
      }

      counterResult = tempStatusCount;
    }

    this.isError = false;
    this.isLoadingGrid = false;

    this.setStatusTotal(counterResult);
  };

  @action _getAssetsStatusCountFetchFailure = (_error: Error | null) => {
    /**/
  };

  @action setIsLoadingGrid = (isLoadingGrid: boolean) => {
    this.isLoadingGrid = isLoadingGrid;
  };

  @action setIsLoadingMenu = (isLoadingMenu: boolean) => {
    this.isLoadingMenu = isLoadingMenu;
  };

  @action setPortalId = (value: string) => {
    this.portalId = value;
  };

  @computed get getStatusCountInStore() {
    return this.statusTotal;
  }

  @computed get filteredAssets() {
    if (this.rootStore.dashboardStore.userFilter) {
      return this.gridAssets.filter(
        (asset) => asset.user === this.rootStore.dashboardStore.userFilter.fullname
      );
    }
    return [];
  }

  @computed get getIsBackdropOpen() {
    return this.isBackdropOpen;
  }

  @computed get getIsLoadingGrid() {
    return this.isLoadingGrid;
  }

  @computed get getIsLoadingMenu() {
    return this.isLoadingMenu;
  }

  @computed get getPortalId() {
    return this.portalId;
  }
}
